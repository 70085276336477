/* @keyframes scrolling { */

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      calc(-1 * var(--marquee-element-width) * var(--marquee-elements))
    );
  }
}
from {
  transform: translateX(0);
}
to {
  transform: translateX(-100%);
}

.logos {
  display: flex;
  align-items: center;
  overflow-x: hidden;
  padding: 60px 0;
  background: white;
  white-space: nowrap;
  position: relative;
  gap: 8rem;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos:hover .logos-slide {
  animation-play-state: paused;
  list-style: none;
  height: 100%;
  display: flex;
  animation: scrolling var(--marquee-animation-duration) linear infinite;
}

.logos-slide {
  display: flex;

  animation: 35s slide infinite linear;
  gap: 10rem;
}

.logos-slide img {
  height: 50px;
  margin: 0 40px;
}

.log-card {
  width: 200px;
  border: 1px solid #00348270;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 2px 1px #00348270;
}

.logo-img {
  width: 50px;
  height: auto;
  display: block;
}

/* .marquee-content {
    list-style: none;
    height: 100%;
    display: flex;
    animation: scrolling var(--marquee-animation-duration) linear infinite;
  } */

@media (max-width: 600px) {
  html {
    font-size: 12px;
  }
  :root {
    --slide-width: 100vw;
    --logos-slide-height: 16vh;
    --logos-slide-elements-displayed: 3;
  }
  .slide:before,
  .slide:after {
    width: 5rem;
  }
}
.custom-carousel-container {
  display: flex;
  overflow: hidden;
  max-width: 100%; /* Adjust the maximum width as needed */
  padding: 5%;
}

.custom-carousel-container .carousel-root {
  display: flex !important;
  transition: transform 0.5s ease-in-out;
}

.custom-carousel-container .carousel {
  display: flex !important;
}

.custom-carousel-container .slide {
  flex: 0 0 auto;
  width: 35%; /* Adjust the width of each image slide */
  margin: 0;
  padding: 0;
}

.custom-carousel-container .carousel .thumbs-wrapper {
  display: none;
}

.image-slider {
  width: 100%;
  overflow: hidden;
}

.slider-wrapper {
  display: flex;
  animation: scrollImages 20s linear infinite;
}

.slide {
  width: 26%;
  height: auto;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5); */
  margin-right: 20px; /* Adjust the margin between images */
  padding: 2%;
}

@keyframes scrollImages {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-80% * 4)); /* Adjust distance to scroll */
  }
}
